<template>
  <div class="friendsCircle_scheme_page">
    <div class="header_common_box">
      <v-header :params="{ title, leftIcon: true }"></v-header>
    </div>
    <van-sidebar v-model="activeKey">
      <van-sidebar-item v-for="(item, index) in sidebarData" :key="index">
        <template #title>
          <div class="item_title">
            <span :class="item.selected?'txt2':'txt'" >{{item.categoryName}}</span>
            <div>
              <van-tag :color="item.selected?'red':'#bcbbb9'" text-color="white" v-if="item.showWeek === 1">周</van-tag>
              <van-tag :color="item.selected?'red':'#bcbbb9'" text-color="white" v-if="item.showMonth === 1" style="margin-left: 5px;">月</van-tag>
            </div>
          </div>
        </template>
      </van-sidebar-item>
    </van-sidebar>

    <div class="right_box" v-if="sidebarData && sidebarData.length">
      <div class="item_box" v-if="sidebarData[activeKey].showIndexPoint1">
        <div class="head">
          <van-tag size="large" color="#e5e5e5" text-color="#666">千位</van-tag>
          <span class="num">最多选{{sidebarData[activeKey].lengthIndexPoint1}}个</span>
        </div>
        <ol class="checkbox">
          <li :class="item.check ? 'checked' : ''" v-for="(item, index) in sidebarData[activeKey].indexPoint1" :key="item.code" @click="setCheckbox(index, item.check, '1' )">{{item.code}}</li>
        </ol>
      </div>
      <div class="item_box" v-if="sidebarData[activeKey].showIndexPoint2">
        <div class="head">
          <van-tag size="large" color="#e5e5e5" text-color="#666">百位</van-tag>
          <span class="num">最多选{{sidebarData[activeKey].lengthIndexPoint2}}个</span>
        </div>
        <ol class="checkbox">
          <li :class="item.check ? 'checked' : ''" v-for="(item, index) in sidebarData[activeKey].indexPoint2" :key="item.code" @click="setCheckbox(index, item.check, '2' )">{{item.code}}</li>
        </ol>
      </div>
      <div class="item_box" v-if="sidebarData[activeKey].showIndexPoint3">
        <div class="head">
          <van-tag size="large" color="#e5e5e5" text-color="#666">十位</van-tag>
          <span class="num">最多选{{sidebarData[activeKey].lengthIndexPoint3}}个</span>
        </div>
        <ol class="checkbox">
          <li :class="item.check ? 'checked' : ''" v-for="(item, index) in sidebarData[activeKey].indexPoint3" :key="item.code" @click="setCheckbox(index, item.check, '3' )">{{item.code}}</li>
        </ol>
      </div>
      <div class="item_box" v-if="sidebarData[activeKey].showIndexPoint4">
        <div class="head">
          <van-tag size="large" color="#e5e5e5" text-color="#666">个位</van-tag>
          <span class="num">最多选{{sidebarData[activeKey].lengthIndexPoint4}}个</span>
        </div>
        <ol class="checkbox">
          <li :class="item.check ? 'checked' : ''" v-for="(item, index) in sidebarData[activeKey].indexPoint4" :key="item.code" @click="setCheckbox(index, item.check, '4' )">{{item.code}}</li>
        </ol>
      </div>
      <div class="item_box" v-if="sidebarData[activeKey].type === '4' || sidebarData[activeKey].type === '5'">
        <div class="head">
          <van-tag size="large" color="#e5e5e5" text-color="#666">{{sidebarData[activeKey].categoryName}}</van-tag>
          <span class="num">最多选{{sidebarData[activeKey].lengthIndexPointGeneral}}个</span>
        </div>
        <ol class="checkbox">
          <li :class="item.check ? 'checked' : ''" v-for="(item, index) in sidebarData[activeKey].indexPointGeneral" :key="item.code" @click="setCheckbox(index, item.check, 'General' )">{{item.code}}</li>
        </ol>
      </div>
      <div class="item_box" v-else-if="sidebarData[activeKey].showIndexPointGeneral">
        <div class="head">
          <van-tag size="large" color="#e5e5e5" text-color="#666">{{sidebarData[activeKey].innerGroupObj ? sidebarData[activeKey].innerGroupObj.inner_text : ''}}</van-tag>
          <span class="num">最多选{{sidebarData[activeKey].lengthIndexPointGeneral}}个</span>
        </div>
        <ol class="checkbox">
          <li v-for="index in 10" :key="index" @click="setCheckboxGeneral(index-1)">{{index - 1}}</li>
        </ol>
      </div>
      <!-- 选择结果展示 -->
      <div class="showCheckbox">
        <p v-if="sidebarData[activeKey].type === '1' || sidebarData[activeKey].type === '6'">[{{sidebarData[activeKey].categoryName}}]</p>
        <p v-if="sidebarData[activeKey].showIndexPoint1">{{`${sidebarData[activeKey].type === '1' ? '千位' : sidebarData[activeKey].categoryName}：${textIndexPoint1}`}}
          <template v-if="sidebarData[activeKey].type != '3'">
            &nbsp;<van-tag size="large" plain type="primary" color="#666" text-color="#666" @click="showKeynote('千位', '1')" v-if="textIndexPointMajorShow('1')">主攻{{textIndexPoint1Major}}</van-tag>
            &nbsp;<van-tag size="large" plain type="primary" color="#666" text-color="#666" @click="showMain('千位', '1')" v-if="textIndexPoint1FocusShow()">重{{textIndexPoint1Focus}}</van-tag>
          </template>
        </p>
        <p v-if="sidebarData[activeKey].showIndexPoint2">{{`${sidebarData[activeKey].type === '1' ? '百位' : sidebarData[activeKey].categoryName}：${textIndexPoint2}`}}
          <template v-if="sidebarData[activeKey].type != '3'">
            &nbsp;<van-tag size="large" plain type="primary" color="#666" text-color="#666" @click="showKeynote('百位', '2')" v-if="textIndexPointMajorShow('2')">主攻{{textIndexPoint2Major}}</van-tag>
            &nbsp;<van-tag size="large" plain type="primary" color="#666" text-color="#666" @click="showMain('百位', '2')" v-if="textIndexPoint2FocusShow()">重{{textIndexPoint2Focus}}</van-tag>
          </template>
        </p>
        <p v-if="sidebarData[activeKey].showIndexPoint3">{{`${sidebarData[activeKey].type === '1' ? '十位' : sidebarData[activeKey].categoryName}：${textIndexPoint3}`}}
          <template v-if="sidebarData[activeKey].type != '3'">
            &nbsp;<van-tag size="large" plain type="primary" color="#666" text-color="#666" @click="showKeynote('十位', '3')" v-if="textIndexPointMajorShow('3')">主攻{{textIndexPoint3Major}}</van-tag>
            &nbsp;<van-tag size="large" plain type="primary" color="#666" text-color="#666" @click="showMain('十位', '3')" v-if="textIndexPoint3FocusShow()">重{{textIndexPoint3Focus}}</van-tag>
          </template>
        </p>
        <p v-if="sidebarData[activeKey].showIndexPoint4">{{`${sidebarData[activeKey].type === '1' ? '个位' : sidebarData[activeKey].categoryName}：${textIndexPoint4}`}}
          <template v-if="sidebarData[activeKey].type != '3'">
            &nbsp;<van-tag size="large" plain type="primary" color="#666" text-color="#666" @click="showKeynote('个位', '4')" v-if="textIndexPointMajorShow('4')">主攻{{textIndexPoint4Major}}</van-tag>
            &nbsp;<van-tag size="large" plain type="primary" color="#666" text-color="#666" @click="showMain('个位', '4')" v-if="textIndexPoint4FocusShow()">重{{textIndexPoint4Focus}}</van-tag>
          </template>
        </p>
        <p v-if="sidebarData[activeKey].type === '4'">{{`${sidebarData[activeKey].categoryName}：${textIndexPointGeneral}`}}
          <template v-if="sidebarData[activeKey].type != '3'">
            &nbsp;<van-tag size="large" plain type="primary" color="#666" text-color="#666" @click="showKeynote('个位', 'General')">主攻{{textIndexPointGeneralMajor}}</van-tag>
            &nbsp;<van-tag size="large" plain type="primary" color="#666" text-color="#666" @click="showMain('个位', 'General')">重{{textIndexPointGeneralFocus}}</van-tag>
          </template>
        </p>
        <p v-if="sidebarData[activeKey].type === '5'">{{`${sidebarData[activeKey].categoryName}：${textIndexPointGeneral}`}}</p>
        <!-- 通用位 -->
        <p v-if="sidebarData[activeKey].type === '6'&&sidebarData[activeKey].showIndexPointGeneral===1">{{sidebarData[activeKey].textIndexPointGeneral}}</p>
      </div>
      <!-- 照片上传 -->
      <div class="photo_upload" v-if="approveType === '2'">
        <van-uploader v-model="sidebarData[activeKey].fileList" :after-read="afterRead" />
      </div>
      <!-- <div class="submitBox" >
        <van-button type="primary" size="small" block color="#fb3e44" @click="onSubmit">发表</van-button>
      </div> -->
      <div class="addBox" v-if="sidebarData[activeKey].selected===false">
        <van-button type="primary" size="small" block color="#fb3e44" @click="onSelect(activeKey)">添加方案</van-button>
      </div>
      <div class="alertBox" v-if="sidebarData[activeKey].selected">
        <van-button type="primary" size="small" block color="gray" @click="cleanSelect(activeKey)">清除方案</van-button>
        <van-button type="primary" size="small" block color="#fb3e44" @click="alertSelect(activeKey)">修改方案</van-button>
        
      </div>
      <div class="submitBox">
        <van-button type="primary" size="small" block color="#fb3e44" @click="showSubmit(activeKey)">發{{selectnumber}}</van-button>

      </div>

    </div>
    <!-- 主攻、重点选择 -->
    <van-dialog v-model="dialogData.show" :title="dialogData.title" show-cancel-button confirmButtonText="保存" cancelButtonText="清除" :before-close="beforeClose" >
      <ol class="dialogCheckbox" v-if="dialogData.obj === 'keynote'">
        <li :class="item.keynote ? 'checked' : ''" v-for="(item, index) in dialogData.arr" :key="item.code" v-show="item.check" @click="setKeynote(index, item.keynote, '1' )">{{item.code}}</li>
      </ol>
      <ol class="dialogCheckbox" v-else>
        <li :class="item.keynote && item.main ? 'checked' : ''" v-for="(item, index) in dialogData.arr" :key="item.code" v-show="item.check && item.keynote" @click="setMain(index, item.main, '1' )">{{item.code}}</li>
      </ol>
    </van-dialog>

    <!-- 已选方案 -->
    <van-popup
    v-model="submitShow" closeable position="bottom" :style="{ height: '100%' }">
            <div class="pophead">
                <!-- <span >{{selectTitle1}}</span> -->
                <span >第{{periods}}期</span>
            </div>
            <!-- 弹窗的内容 -->
            <div class="popbody">
              <div v-for="(item,index) in selectList" :key="index"  style="margin-bottom:0.05rem; margin-top:0;background:white">
                 <p class="names">
                    {{item.categoryName}}
                  </p>
                  <div class="desc">
                    <p v-if="item.textIndexPoint1!==''">
                      千位：{{item.textIndexPoint1}}
                      <span v-if="item.textIndexPoint1Major!==''">
                        主攻：{{item.textIndexPoint1Major}}
                        <span v-if="item.textIndexPoint1Focus!==''">
                          重点：{{item.textIndexPoint1Focus}}
                        </span>
                      </span>
                    </p>
                    <p v-if="item.textIndexPoint2!==''">
                      百位：{{item.textIndexPoint2}}
                      <span v-if="item.textIndexPoint2Major!==''">
                        主攻：{{item.textIndexPoint2Major}}
                        <span v-if="item.textIndexPoint2Focus!==''">
                          重点：{{item.textIndexPoint2Focus}}
                        </span>
                      </span>
                    </p>
                    <p v-if="item.textIndexPoint3!==''">
                      十位：{{item.textIndexPoint3}}
                      <span v-if="item.textIndexPoint3Major!==''">
                        主攻：{{item.textIndexPoint3Major}}
                        <span v-if="item.textIndexPoint3Focus!==''">
                          重点：{{item.textIndexPoint3Focus}}
                        </span>
                      </span>
                    </p>
                    <p v-if="item.textIndexPoint4!==''">
                      个位：{{item.textIndexPoint4}}
                      <span v-if="item.textIndexPoint4Major!==''">
                        主攻：{{item.textIndexPoint4Major}}
                        <span v-if="item.textIndexPoint4Focus!==''">
                          重点：{{item.textIndexPoint4Focus}}
                        </span>
                      </span>
                    </p>
                    <!-- 通用 -->
                    <p v-if="item.textIndexPointGeneral!==''">
                      {{item.textIndexPointGeneral}}
                      <span v-if="item.textIndexPointGeneralMajor!==''">
                        主攻：{{item.textIndexPointGeneralMajor}}
                        <span v-if="item.textIndexPointGeneralFocus!==''">
                          重点：{{item.textIndexPointGeneralFocus}}
                        </span>
                      </span>
                    </p>
                    
                    
                    
                  </div>
                  <p class="datatype">
                    <van-tag :color="'#bcbbb9'" text-color="white" v-if="item.showWeek === 1">周</van-tag>
                    <van-tag :color="'#bcbbb9'" text-color="white" v-if="item.showMonth === 1" style="margin-left: 5px;">月</van-tag>
                  </p>
              </div>
             
              
            </div>

            <div class="popbutton">
                <van-button type="default" style="background-color: #7a7a7a" @click="submitShow=false">修改</van-button>
                <van-button type="danger" style="background-color: red" @click="submit">发表</van-button>
            </div>
    </van-popup>
  </div>
</template>

<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
  components: {
    vHeader,
  },
  data() {
    return {
      submitShow:false, //发表弹窗
      title: this.$route.meta.title,
      approveType: '1',
      activeKey: 0,
      sidebarData: [],
      dialogData: {
        show: false,
        obj: '', // 主攻、重
        index: '', // 千、百、个、十位
        title: '',
        arr: []
      }
    }
  },
  computed: {
    
    periods(){//当前期数+1
      let num
      let type=this.$store.state.common.lotteryKind
      if(type==='7'){
        const seven=JSON.parse(localStorage.getItem('seven'))
        let n=seven.periodText.substr(0,seven.periodText.length-1)
        num=parseInt(n)+1
      }
      if(type==='5'){
        const five=JSON.parse(localStorage.getItem('five'))
        let n=five.periodText.substr(0,five.periodText.length-1)
        num=parseInt(n)+1
      }
      return num
    },
    //已选方案的列表
    selectList(){
      return this.sidebarData.filter((item)=>{
        if(item.selected)
        return item
      })
    },
    //已选方案的个数
    selectnumber(){
      return this.selectList.length
    },
    //已选带周的方案的个数
    selectedweeknum(){
      let num=0
      this.selectList.filter((item)=>{
        if(item.showWeek===1){
          num++
        }
      })
      return num
    },
    //已选带月的方案的个数
    selectedmonthnum(){
      let num=0
      this.selectList.filter((item)=>{
        if(item.showMonth===1){
          num++
        }
      })
      return num
    },
    // 千位内容
    textIndexPoint1() { return this.sidebarData[this.activeKey].indexPoint1.filter(item => { return item.check }).map(item => { return item.code }).join('') },
    // 千位主攻
    textIndexPoint1Major() { return this.sidebarData[this.activeKey].indexPoint1.filter(item => { return item.check && item.keynote }).map(item => { return item.code }).join('') },
    // 千位重点
    textIndexPoint1Focus() { return this.sidebarData[this.activeKey].indexPoint1.filter(item => { return item.check && item.keynote && item.main }).map(item => { return item.code }).join('') },
    // 百位内容
    textIndexPoint2() { return this.sidebarData[this.activeKey].indexPoint2.filter(item => { return item.check }).map(item => { return item.code }).join('') },
    // 百位主攻
    textIndexPoint2Major() { return this.sidebarData[this.activeKey].indexPoint2.filter(item => { return item.check && item.keynote }).map(item => { return item.code }).join('') },
    // 百位重点
    textIndexPoint2Focus() { return this.sidebarData[this.activeKey].indexPoint2.filter(item => { return item.check && item.keynote && item.main }).map(item => { return item.code }).join('') },
    // 十位内容
    textIndexPoint3() { return this.sidebarData[this.activeKey].indexPoint3.filter(item => { return item.check }).map(item => { return item.code }).join('') },
    // 十位主攻
    textIndexPoint3Major() { return this.sidebarData[this.activeKey].indexPoint3.filter(item => { return item.check && item.keynote }).map(item => { return item.code }).join('') },
    // 十位重点
    textIndexPoint3Focus() { return this.sidebarData[this.activeKey].indexPoint3.filter(item => { return item.check && item.keynote && item.main }).map(item => { return item.code }).join('') },
    // 个位内容
    textIndexPoint4() { return this.sidebarData[this.activeKey].indexPoint4.filter(item => { return item.check }).map(item => { return item.code }).join('') },
    // 个位主攻
    textIndexPoint4Major() { return this.sidebarData[this.activeKey].indexPoint4.filter(item => { return item.check && item.keynote }).map(item => { return item.code }).join('') },
    // 个位重点
    textIndexPoint4Focus() { return this.sidebarData[this.activeKey].indexPoint4.filter(item => { return item.check && item.keynote && item.main }).map(item => { return item.code }).join('') },
    // 头尾合、中肚合内容
    textIndexPointGeneral() { return this.sidebarData[this.activeKey].indexPointGeneral.filter(item => { return item.check }).map(item => { return item.code }).join('') },
    // 头尾合、中肚合个位主攻
    textIndexPointGeneralMajor() { 
      if(this.sidebarData[this.activeKey].type!=='6')
      return this.sidebarData[this.activeKey].indexPointGeneral.filter(item => { return item.check && item.keynote }).map(item => { return item.code }).join('') 
      else
      return ''
    },
    // 头尾合、中肚合个位重点
    textIndexPointGeneralFocus() { 
      if(this.sidebarData[this.activeKey].type!=='6')
      return this.sidebarData[this.activeKey].indexPointGeneral.filter(item => { return item.check && item.keynote && item.main }).map(item => { return item.code }).join('')
      else
      return '' 
    }
  },
  created() {
    if (this.$store.state.common.loginInfo.access_token) {
      this.onLoad()
      this.approveType = this.$route.query.key || '1'
    } else {
      this.$router.replace('/login')
    }

  },
  mounted:function(){
    //判断网页是什么打开
    let isWeiXin = isWeixin()
    if(isWeiXin){
      this.showHeader = false
    }
    if(this.showHeader==false){
      document.title = ''
    }
  },
  methods: {
    //判断是否按要求选择
    allowornot(n){
      const sidebarItemData = this.sidebarData[n]
      console.log('sidebarItemData', sidebarItemData)
      let flag = false
      if (sidebarItemData.type !== '6') {
        if (sidebarItemData.showIndexPoint1 && !this.textIndexPoint1 || sidebarItemData.showIndexPoint2 && !this.textIndexPoint2 || sidebarItemData.showIndexPoint3 && !this.textIndexPoint3 || sidebarItemData.showIndexPoint4 && !this.textIndexPoint4 || sidebarItemData.showIndexPointGeneral && !this.textIndexPointGeneral) {
          flag = true
        }
      } else {
        let str = sidebarItemData.indexPointGeneral2.length
        let max_num = parseInt(sidebarItemData.innerGroupObj.max_num)
        if (str % max_num === 0) {
          flag = false
        } else {
          flag = true
        }
      }
      return flag
    },
    //显示方案弹窗
    showSubmit(n){
      if (this.allowornot(n)) {
        this.$dialog.alert({
          title: '提示',
          message: '预测数据，选择不完整！',
        }).then(() => {
          // on close
        });
        return false
      } else{
        this.submitShow=true
        console.log(this.selectList)
        this.getinfo(n)
      }
    },
    //获取信息
    getinfo(n){
      
        this.sidebarData[n].textIndexPoint1=this.textIndexPoint1//范围信息
        this.sidebarData[n].textIndexPoint1Focus=this.textIndexPoint1Focus//重点信息
        this.sidebarData[n].textIndexPoint1Major=this.textIndexPoint1Major//主攻信息
        this.sidebarData[n].textIndexPoint2=this.textIndexPoint2
        this.sidebarData[n].textIndexPoint2Focus=this.textIndexPoint2Focus
        this.sidebarData[n].textIndexPoint2Major=this.textIndexPoint2Major
        this.sidebarData[n].textIndexPoint3=this.textIndexPoint3
        this.sidebarData[n].textIndexPoint3Focus=this.textIndexPoint3Focus
        this.sidebarData[n].textIndexPoint3Major=this.textIndexPoint3Major
        this.sidebarData[n].textIndexPoint4=this.textIndexPoint4
        this.sidebarData[n].textIndexPoint4Focus=this.textIndexPoint4Focus
        this.sidebarData[n].textIndexPoint4Major=this.textIndexPoint4Major
        if(this.sidebarData[n].textIndexPointGeneral===''&&this.sidebarData[n].type!=='6')
        this.sidebarData[n].textIndexPointGeneral=this.textIndexPointGeneral
        if(this.sidebarData[n].type!=='6')
        this.sidebarData[n].textIndexPointGeneralFocus=this.textIndexPointGeneralFocus
        if(this.sidebarData[n].type!=='6')
        this.sidebarData[n].textIndexPointGeneralMajor=this.textIndexPointGeneralMajor
      
    },
    //添加方案
    onSelect(n){
      // 这里是方案限制功能，暂时注释，等决定好了再开放
      // console.log('要添加的方案',this.sidebarData[n])
      // console.log('含有周的方案个数',this.selectedweeknum)
      // console.log('含有月的方案个数',this.selectedmonthnum)
      // if(this.sidebarData[n].showWeek===1){//含有周的方案
      //   if(this.selectnumber>=4){
      //     this.$dialog.alert({
      //     title: '提示',
      //     message: '每期最多只能发布四项预测（包括追加贴）',
      //   }).then(() => {
      //     // on close
      //   });
      //   return false 
      //   }else{
      //     if(this.selectedweeknum>=2){
      //       this.$dialog.alert({
      //         title: '提示',
      //         message: '每期最多只能发布两项含有"周"的预测',
      //       }).then(() => {
      //         // on close
      //       });
      //       return false
      //     }
      //   }
        
      // }else{//仅含有月的方案
      //   if(this.selectnumber>=4){
      //     this.$dialog.alert({
      //     title: '提示',
      //     message: '每期最多只能发布四项预测（包括追加贴）',
      //   }).then(() => {
      //     // on close
      //   });
      //   return false 
      //   }
      // }
      
      
      if (this.approveType == '2' && !this.sidebarData[n].fileList.length) {
        this.$dialog.alert({
          title: '提示',
          message: '你选择【规律预测】频道发表帖子，需要上传规律图，带规律的帖子会赢得更多的粉丝和影响力哟~',
        }).then(() => {
          // on close
        });
        return false
      }
      if (this.allowornot(n)) {
        this.$dialog.alert({
          title: '提示',
          message: '预测数据，选择不完整！',
        }).then(() => {
          // on close
        });
        return false
      } else{
        this.sidebarData[n].selected=!this.sidebarData[n].selected
        this.getinfo(n)
       
      }

    },
    //清除方案
    cleanSelect(n){
      
        this.sidebarData[n].selected=!this.sidebarData[n].selected
        this.sidebarData[n].indexPoint1 = []
        this.sidebarData[n].indexPoint2 = []
        this.sidebarData[n].indexPoint3 = []
        this.sidebarData[n].indexPoint4 = []
        this.sidebarData[n].indexPointGeneral = []
        this.sidebarData[n].textIndexPointGeneral = ''
        this.sidebarData[n].indexPointGeneral2 = ''
        
            for (var i = 0; i < 10; i++) {
              this.sidebarData[n].indexPoint1.push({ code: i, check: false, keynote: false, main: false })
              this.sidebarData[n].indexPoint2.push({ code: i, check: false, keynote: false, main: false })
              this.sidebarData[n].indexPoint3.push({ code: i, check: false, keynote: false, main: false })
              this.sidebarData[n].indexPoint4.push({ code: i, check: false, keynote: false, main: false })
              this.sidebarData[n].indexPointGeneral.push({ code: i, check: false, keynote: false, main: false })
            }
        
      
    },
    //修改方案
    alertSelect(n){
      if (this.allowornot(n)) {
        this.$dialog.alert({
          title: '提示',
          message: '预测数据，选择不完整！',
        }).then(() => {
          // on close
        });
        return false
      } else{
          this.$toast(`修改成功`);
          this.getinfo(n)
      }
    },
    //主攻显示
    textIndexPointMajorShow(index){
      let number_length = this.sidebarData[this.activeKey]['indexPoint' + index].filter(item =>{return item.check}).length;
      if (number_length > 1)
      {
        return true;
      }else
      {
        return false;
      }
    },
    //千位重点
    textIndexPoint1FocusShow(){
      let number_length = this.sidebarData[this.activeKey].indexPoint1.filter(item => { return item.check && item.keynote }).map(item => { return item.code }).join('').length;
      if (number_length > 1)
      {
        return true;
      }else
      {
        return false;
      }
    },
    //百位重点
    textIndexPoint2FocusShow(){
      let number_length = this.sidebarData[this.activeKey].indexPoint2.filter(item => { return item.check && item.keynote }).map(item => { return item.code }).join('').length;
      if (number_length > 1)
      {
        return true;
      }else
      {
        return false;
      }
    },
    //十位重点
    textIndexPoint3FocusShow(){
      let number_length = this.sidebarData[this.activeKey].indexPoint3.filter(item => { return item.check && item.keynote }).map(item => { return item.code }).join('').length;
      if (number_length > 1)
      {
        return true;
      }else
      {
        return false;
      }
    },
    //个位重点
    textIndexPoint4FocusShow(){
      let number_length = this.sidebarData[this.activeKey].indexPoint4.filter(item => { return item.check && item.keynote }).map(item => { return item.code }).join('').length;
      if (number_length > 1)
      {
        return true;
      }else
      {
        return false;
      }
    },
    async onLoad() {
      const res = await this.$HTTP.get(this, this.$API['API_USERCENTER_FORUM_CATEGORY'], { 'lottery_kind': this.$store.state.common.lotteryKind }, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true)
      console.log(res)
      if (res && res.data && res.data.length) {
        let arr = res.data.map(item => {
          const indexPoint1 = [], indexPoint2 = [], indexPoint3 = [], indexPoint4 = [], indexPointGeneral = []
          for (var i = 0; i < 10; i++) {
            indexPoint1.push({ code: i, check: false, keynote: false, main: false })
            indexPoint2.push({ code: i, check: false, keynote: false, main: false })
            indexPoint3.push({ code: i, check: false, keynote: false, main: false })
            indexPoint4.push({ code: i, check: false, keynote: false, main: false })
            indexPointGeneral.push({ code: i, check: false, keynote: false, main: false })
          }
          if (item.innerGroupObj) {
            item.innerGroupObj = JSON.parse(item.innerGroupObj)
          }
          switch (item.categoryName) {
            case '头尾': case '中肚': case 'ABXX': case 'AXCX': case 'XBXD': case 'XXCD': case 'ABCX': case 'ABXD': case 'AXCD': case 'XBCD': case '芝麻':
              item.type = '1'
              break
            case '定头': case '定百': case '定十': case '定尾':
              item.type = '2'
              break
            case '杀头': case '杀百': case '杀十': case '杀尾':
              item.type = '3'
              break
            case '头尾合': case '中肚合':
              item.type = '4'
              break
            case '稳码': case '死数':
              item.type = '5'
              break
            case '二字现': case '三字现':
              item.type = '6'
              break
            default:
              item.type = '1'
              break
          }
          let itemData = {
            ...item,
            textIndexPointGeneral: '', // 通用位内容
            textIndexPointGeneralFocus: '', // 通用位重点
            textIndexPointGeneralMajor: '', // 通用位主攻
            indexPoint1,
            indexPoint2,
            indexPoint3,
            indexPoint4,
            selected:false, //是否被选
            textIndexPoint1:'',//范围信息
            textIndexPoint1Focus:'',//主攻信息
            textIndexPoint1Major:'',//重点信息
            textIndexPoint2:'',//范围信息
            textIndexPoint2Focus:'',//主攻信息
            textIndexPoint2Major:'',//重点信息
            textIndexPoint3:'',//范围信息
            textIndexPoint3Focus:'',//主攻信息
            textIndexPoint3Major:'',//重点信息
            textIndexPoint4:'',//范围信息
            textIndexPoint4Focus:'',//主攻信息
            textIndexPoint4Major:'',//重点信息
            fileList: []
          }
          if (item.type !== '6') {
            itemData.indexPointGeneral = indexPointGeneral
          }
          return itemData
        })
        this.sidebarData = arr
      }
      console.log(this.sidebarData)
    },
    // 千、百、十、个位选择设置
    setCheckbox(index, check, obj) {
      this.sidebarData[this.activeKey]['indexPoint' + obj][index].check = !check
      let maxlength = this.sidebarData[this.activeKey]['lengthIndexPoint' + obj]
      let arr = this.sidebarData[this.activeKey]['indexPoint' + obj] || []
      let thatlength = arr.filter(item => { return item.check }).length
      if (thatlength > maxlength) {
        this.$toast(`最多选${maxlength}个`);
        this.sidebarData[this.activeKey]['indexPoint' + obj][index].check = check
      }
      
            
    },
    // 通用位选择设置
    setCheckboxGeneral(val) {
      // 二、三字现选择
      if (this.sidebarData[this.activeKey].innerGroupObj && this.sidebarData[this.activeKey].innerGroupObj.max_num) {
        // 获取字符串
        const str = JSON.parse(JSON.stringify(this.sidebarData[this.activeKey].indexPointGeneral2 || '')) + val + ''
        // 逗号分隔的字符串
        const textIndexPointGeneral = this.strInsert(str, this.sidebarData[this.activeKey].innerGroupObj.max_num)
        // 验证是否有相同的方案
        let isRepeat = this.isRepeat(textIndexPointGeneral.split(','))
        if (isRepeat) {
          this.$toast(`已有相同方案`)
          return
        }
        // 验证方案个数
        if (textIndexPointGeneral.split(',').length > this.sidebarData[this.activeKey].lengthIndexPointGeneral) {
          this.$toast(`最多选择${this.sidebarData[this.activeKey].lengthIndexPointGeneral}个`)
          return
        }
        // 保存数据
        this.sidebarData[this.activeKey].indexPointGeneral2 = str
        this.sidebarData[this.activeKey].textIndexPointGeneral = textIndexPointGeneral
        // console.log(this.sidebarData[this.activeKey].indexPointGeneral)
        // console.log(this.sidebarData[this.activeKey].textIndexPointGeneral)
      }
    },

    // 显示主攻
    showKeynote(name, index) {
      this.dialogData = {
        show: true,
        obj: 'keynote',
        index,
        title: name + '：选择主攻',
        arr: JSON.parse(JSON.stringify(this.sidebarData[this.activeKey]['indexPoint' + index]))
      }
    },
    // 设置主攻
    setKeynote(index, check) {
      this.dialogData.arr[index].keynote = !check
      let maxlength = this.dialogData.arr.filter(item => { return item.check }).length
      let thatlength = this.dialogData.arr.filter(item => { return item.check && item.keynote }).length
      if (thatlength >= maxlength) {
        this.$toast(`最多选${maxlength - 1}个`);
        this.dialogData.arr[index].keynote = check
      }
    },
    // 显示重点
    showMain(name, index) {
      this.dialogData = {
        show: true,
        obj: 'main',
        index,
        title: name + '：选择重点',
        arr: JSON.parse(JSON.stringify(this.sidebarData[this.activeKey]['indexPoint' + index]))
      }
    },
    // 设置重点
    setMain(index, check) {
      this.dialogData.arr[index].main = !check
      let maxlength = this.dialogData.arr.filter(item => { return item.check && item.keynote }).length
      let thatlength = this.dialogData.arr.filter(item => { return item.check && item.keynote && item.main }).length
      if (thatlength >= maxlength) {
        this.$toast(`最多选${maxlength - 1}个`);
        this.dialogData.arr[index].main = check
      }
    },
    beforeClose: function (action, done) {
      done(false) //不关闭弹框
      if (action === 'confirm') {
        this.dialogData.show = false
        // 主攻、重点确认
        this.sidebarData[this.activeKey]['indexPoint' + this.dialogData.index] = this.dialogData.arr
      } else if (action === 'cancel') {
        // 主攻、重点取消
        this.dialogData.arr.map(item => {
          return {
            ...item,
            keynote: this.dialogData.obj === 'keynote' ? item.keynote = false : item.main = false
          }
        })
      }
    },
    // async onSubmit() {
    //   const sidebarItemData = this.sidebarData[this.activeKey]
    //   console.log('sidebarItemData', sidebarItemData)
    //   let flag = false
    //   if (sidebarItemData.type !== '6') {
    //     if (sidebarItemData.showIndexPoint1 && !this.textIndexPoint1 || sidebarItemData.showIndexPoint2 && !this.textIndexPoint2 || sidebarItemData.showIndexPoint3 && !this.textIndexPoint3 || sidebarItemData.showIndexPoint4 && !this.textIndexPoint4 || sidebarItemData.showIndexPointGeneral && !this.textIndexPointGeneral) {
    //       flag = true
    //     }
    //   } else {
    //     let str = sidebarItemData.indexPointGeneral.length
    //     let max_num = parseInt(sidebarItemData.innerGroupObj.max_num)
    //     if (str % max_num === 0) {
    //       flag = false
    //     } else {
    //       flag = true
    //     }
    //   }
    //   if (flag) {
    //     this.$dialog.alert({
    //       title: '提示',
    //       message: '预测数据，选择不完整！',
    //     }).then(() => {
    //       // on close
    //     });
    //     return false
    //   }

    //   if (this.approveType == '2' && !sidebarItemData.fileList.length) {
    //     this.$dialog.alert({
    //       title: '提示',
    //       message: '你选择【规律预测】频道发表帖子，需要上传规律图，带规律的帖子会赢得更多的粉丝和影响力哟~',
    //     }).then(() => {
    //       // on close
    //     });
    //     return false
    //   }

    //   let data = {
    //     approveType: this.approveType, //	integer($int32) 分类 审核类型： 1免审 2带规律图纸 3只是图纸 4鸡汤
    //     categoryId: sidebarItemData.categoryId, //		integer($int64)方案ID
    //     contents: '', //		string 帖子内容
    //     imagesUrlArray: [],
    //     lotteryKind: this.$store.state.common.lotteryKind, //	integer($int32) 7:七星彩 5:排列五
    //     points: '', //	integer($int32)帖子需要的积分
    //     textIndexPoint1: this.textIndexPoint1, //		string千位内容
    //     textIndexPoint1Focus: this.textIndexPoint1Focus, //		string千位重点
    //     textIndexPoint1Major: this.textIndexPoint1Major, //		string千位主攻
    //     textIndexPoint2: this.textIndexPoint2, //		string百位内容
    //     textIndexPoint2Focus: this.textIndexPoint2Focus, //		string百位重点
    //     textIndexPoint2Major: this.textIndexPoint2Major, //		string百位主攻
    //     textIndexPoint3: this.textIndexPoint3, //		string十位内容
    //     textIndexPoint3Focus: this.textIndexPoint3Focus, //		string十位重点
    //     textIndexPoint3Major: this.textIndexPoint3Major, //		string十位主攻
    //     textIndexPoint4: this.textIndexPoint4, //		string个位内容
    //     textIndexPoint4Focus: this.textIndexPoint4Focus, //		string个位重点
    //     textIndexPoint4Major: this.textIndexPoint4Major, //		string个位主攻
    //     vipLevel: '', //		integer($int32)看此帖子的级别
    //   }
    //   if (sidebarItemData.type !== '6') {
    //     data.textIndexPointGeneral = this.textIndexPointGeneral || '' //		string通用位内容
    //     data.textIndexPointGeneralFocus = this.textIndexPointGeneralFocus || '' //		string通用位重点
    //     data.textIndexPointGeneralMajor = this.textIndexPointGeneralMajor || '' //		string通用位主攻
    //   } else {
    //     data.textIndexPointGeneral = sidebarItemData.textIndexPointGeneral || '' //		string通用位内容
    //     data.textIndexPointGeneralFocus = '' //		string通用位重点
    //     data.textIndexPointGeneralMajor = '' //		string通用位主攻
    //   }
    //   let contentsHtml = ''
    //   if (sidebarItemData.type === '1') {
    //     contentsHtml = `<p>[${sidebarItemData.categoryName}]</p>`
    //     if (sidebarItemData.showIndexPoint1) {
    //       contentsHtml += `<p>${this.textIndexPoint1!=='' ? '千位' + this.textIndexPoint1 : ''} ${this.textIndexPoint1Major!=='' ? '主攻' + this.textIndexPoint1Major : ''} ${this.textIndexPoint1Focus!=='' ? '重' + this.textIndexPoint1Focus : ''}</p>`
    //     }
    //     if (sidebarItemData.showIndexPoint2) {
    //       contentsHtml += `<p>${this.textIndexPoint2 ? '百位' + this.textIndexPoint2 : ''} ${this.textIndexPoint2Major ? '主攻' + this.textIndexPoint2Major : ''} ${this.textIndexPoint2Focus ? '重' + this.textIndexPoint2Focus : ''}</p>`
    //     }
    //     if (sidebarItemData.showIndexPoint3) {
    //       contentsHtml += `<p>${this.textIndexPoint3 ? '十位' + this.textIndexPoint3 : ''} ${this.textIndexPoint3Major ? '主攻' + this.textIndexPoint3Major : ''} ${this.textIndexPoint3Focus ? '重' + this.textIndexPoint3Focus : ''}</p>`
    //     }
    //     if (sidebarItemData.showIndexPoint4) {
    //       contentsHtml += `<p>${this.textIndexPoint4 ? '个位' + this.textIndexPoint4 : ''} ${this.textIndexPoint4Major ? '主攻' + this.textIndexPoint4Major : ''} ${this.textIndexPoint4Focus ? '重' + this.textIndexPoint4Focus : ''}</p>`
    //     }
    //   } else if (sidebarItemData.type === '2' || sidebarItemData.type === '3' || sidebarItemData.type === '4' || sidebarItemData.type === '5') {
    //     let num = sidebarItemData.showIndexPoint1 ? '1' : sidebarItemData.showIndexPoint2 ? '2' : sidebarItemData.showIndexPoint3 ? '3' : sidebarItemData.showIndexPoint4 ? '4' : sidebarItemData.showIndexPointGeneral ? 'General' : ''
    //     const textIndexPoint = `textIndexPoint${num}`
    //     const textIndexPointMajor = `textIndexPoint${num}Major`
    //     const textIndexPointFocus = `textIndexPoint${num}Focus`
    //     contentsHtml += `<p>[${sidebarItemData.categoryName}] ${this[textIndexPoint]} ${this[textIndexPointMajor] ? '主攻' + this[textIndexPointMajor] : ''} ${this[textIndexPointFocus] ? '重' + this[textIndexPointFocus] : ''}</p>`
    //   } else if (sidebarItemData.type === '6') {
    //     contentsHtml += `<p>[${sidebarItemData.categoryName}] ${sidebarItemData.textIndexPointGeneral}</p>`
    //   }
    //   data.contents = contentsHtml

    //   if(this.approveType === '2'){
    //     data.imagesUrlArray = sidebarItemData.fileList.map(item => { return item.url })
    //   }

    //   const res = await this.$HTTP.post(this, 'API_USERCENTER_FORUM_ONE', data, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true)
    //   if (res.statusCode === 200) {
    //     this.$toast('发表成功');
    //     setTimeout(() => {
    //       this.$router.go(-2);
    //     }, 1000)
    //   }
    // },
    //发布论坛
    async submit(){
      let data = {
        approveType: this.approveType, //	integer($int32) 分类 审核类型： 1免审 2带规律图纸 3只是图纸 4鸡汤
        categoryId: 0, //		integer($int64)方案ID
        contents: '', //		string 帖子内容
        forumDetailsModels:this.selectList,
        imagesUrlArray: [],
        lotteryKind: this.$store.state.common.lotteryKind, //	integer($int32) 7:七星彩 5:排列五
        points: '', //	integer($int32)帖子需要的积分
        vipLevel: '', //		integer($int32)看此帖子的级别
      }
      // this.selectList.forEach((item)=>{
       

      //   let contentsHtml = ''
      // if (item.type === '1') {
      //   contentsHtml = `<p>[${item.categoryName}]</p>`
      //   if (item.showIndexPoint1) {
      //     contentsHtml += `<p>${item.textIndexPoint1!=='' ? '千位' + item.textIndexPoint1 : ''} ${item.textIndexPoint1Major!=='' ? '主攻' + item.textIndexPoint1Major : ''} ${item.textIndexPoint1Focus!=='' ? '重' + item.textIndexPoint1Focus : ''}</p>`
      //   }
      //   if (item.showIndexPoint2) {
      //     contentsHtml += `<p>${item.textIndexPoint2!=='' ? '百位' + item.textIndexPoint2 : ''} ${item.textIndexPoint2Major!=='' ? '主攻' + item.textIndexPoint2Major : ''} ${item.textIndexPoint2Focus!=='' ? '重' + item.textIndexPoint2Focus : ''}</p>`
      //   }
      //   if (item.showIndexPoint3) {
      //     contentsHtml += `<p>${item.textIndexPoint3!=='' ? '十位' + item.textIndexPoint3 : ''} ${item.textIndexPoint3Major!=='' ? '主攻' + item.textIndexPoint3Major : ''} ${item.textIndexPoint3Focus!=='' ? '重' + item.textIndexPoint3Focus : ''}</p>`
      //   }
      //   if (item.showIndexPoint4) {
      //     contentsHtml += `<p>${item.textIndexPoint4!=='' ? '个位' + item.textIndexPoint4 : ''} ${item.textIndexPoint4Major!=='' ? '主攻' + item.textIndexPoint4Major : ''} ${item.textIndexPoint4Focus!=='' ? '重' + item.textIndexPoint4Focus : ''}</p>`
      //   }
      // } else if (item.type === '2' || item.type === '3' || item.type === '4' || item.type === '5') {
      //   let num = item.showIndexPoint1 ? '1' : item.showIndexPoint2 ? '2' : item.showIndexPoint3 ? '3' : item.showIndexPoint4 ? '4' : item.showIndexPointGeneral ? 'General' : ''
      //   const textIndexPoint = `textIndexPoint${num}`
      //   const textIndexPointMajor = `textIndexPoint${num}Major`
      //   const textIndexPointFocus = `textIndexPoint${num}Focus`
      //   contentsHtml += `<p>[${item.categoryName}] ${item[textIndexPoint]} ${item[textIndexPointMajor] ? '主攻' + item[textIndexPointMajor] : ''} ${item[textIndexPointFocus] ? '重' + item[textIndexPointFocus] : ''}</p>`
      // } else if (item.type === '6') {
      //   contentsHtml += `<p>[${item.categoryName}] ${item.textIndexPointGeneral}</p>`
      // }
      // item.contents = contentsHtml
      // console.log(item.contents)
      // })
     
      
      if(this.approveType === '2'){
        this.selectList.forEach((item)=>{
          item.fileList.forEach((n)=>{
            data.imagesUrlArray.push(n.url)
          })
        })
        // data.imagesUrlArray.concat(item.fileList.map(item => { return item.url }))
        // data.imagesUrlArray = this.sidebarData.fileList.map(item => { return item.url })
      }
      console.log(data)
      const res = await this.$HTTP.post(this, 'API_USERCENTER_FORUM_ONE', data, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true)
      console.log(res)
      if (res.statusCode === 200) {
        this.$toast('发表成功');
        setTimeout(() => {
          this.$router.go(-2);
        }, 1000)
      }
    },
    // str 字符串  length 多少位添加逗号
    strInsert(str, length) {
      var reg = new RegExp('\\w{1,' + length + '}', 'g');
      let ma = str.match(reg);
      return ma.join(',');
    },
    // 判断数组是否重复
    isRepeat(arr) {
      var hash = {};
      for (var i in arr) {
        if (hash[arr[i]]) {
          return true;
        }
        hash[arr[i]] = true;
      }
      return false;
    },
    // 上传照片
    afterRead(src) {
      // 此时可以自行将文件上传至服务器
      let file = src.file
      let param = new FormData()       // 创建form对象     
      param.append('file', file)       // 通过append向form对象添加数据
      let config = {
        headers: { 'Content-Type': 'multipart/form-data', access_token: this.$store.state.common.loginInfo.access_token }
      }
      // this.$refs.refIdInput.value = ''
      this.$toast.loading({ duration: 0, message: '加载中...', forbidClick: true });
      this.$axios.post(this.$API['API_FILESYSTEM_FILEINFO_FILE'] + '?fileSource=LOCAL', param, config).then((res) => {
        if (res?.data?.fileUrl) {
          let len = this.sidebarData[this.activeKey].fileList.length - 1
          this.sidebarData[this.activeKey].fileList[len].url = res.data.fileUrl
          this.$toast('上传成功');
        } else {
          this.$toast(res.data.content.resultMessage || '上传失败！');
        }
      }).catch(() => {
        this.$toast("图片上传失败，请重新上传!")
      })
    },
  }
};
</script>

<style lang="less" >

.friendsCircle_scheme_page {
  .header_common_box {
    border-bottom: 1px solid #eee;
  }
  .van-sidebar {
    position: fixed;
    top: 45px;
    left: 0;
    bottom: 0;
    width: 120px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    .van-sidebar-item {
      padding: 12px;
      background-color: #f0efed;
      .van-sidebar-item__text {
        width: 100%;
        .item_title {
          display: flex;
          align-items: center;
          .txt {
            display: block;
            flex: 1;
          }
          .txt2 {
            display: block;
            color:red;
            flex: 1;
          }
        }
      }
    }
    .van-sidebar-item--select {
      background-color: white;
      font-weight: initial;
      .txt {
        color: #fb3e44;
      }
    }
    .van-sidebar-item--select::before {
      display: none;
    }
  }
  .right_box {
    position: fixed;
    top: 45px;
    left: 120px;
    bottom: 0;
    right: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    font-size: 15px;

    .item_box {
      margin-top: 10px;
      .head {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 12px;
        .num {
          font-size: 14px;
          color: #999;
        }
      }
      .checkbox {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        li {
          background-color: #f1f1f1;
          width: 34px;
          height: 34px;
          line-height: 34px;
          text-align: center;
          border-radius: 50%;
          margin: 5px;
          color: #666;
        }
        li.checked,
        li:active {
          color: white;
          background-color: #fb3e44;
        }
      }
    }
    .showCheckbox {
      margin: 12px;
      background-color: #f1f1f1;
      border-radius: 5px;
      font-size: 14px;
      p {
        padding: 4px 6px;
        margin: 0;
        word-break: break-word;
      }
    }
    .addBox {
      margin: 12px;
    }
    .alertBox{
      .van-button{
        width: 3rem;
        margin: 0 0.1rem;
        display: inline-block;
      }
    }
    .submitBox{
      .van-button{
        border-radius: 1rem;
        width: 1.5rem;
        margin: 0 0.1rem;
        display: inline-block;
        position: fixed;
        right: -0.6rem;
        bottom: 3rem;
      }
    }
  }
  .dialogCheckbox {
    display: flex;
    flex-wrap: wrap;
    margin: 12px;
    li {
      background-color: #f1f1f1;
      width: 34px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      border-radius: 50%;
      margin: 7px;
      color: #666;
    }
    li.checked,
    li:active {
      color: white;
      background-color: #fb3e44;
    }
  }
  .photo_upload {
    margin: 12px;
    .van-uploader__upload {
      background-color: #f1f1f1;
    }
  }
}
.pophead{
    height:1.2rem;
    font-size:0.6rem;
    text-align: center;
  
}
.popbody{
    
   
    height:80%;
    overflow:auto;
    background:rgb(244, 241, 241);
    
    .names{
      display: inline-block;
      width: 2rem;
      height: auto;
      text-align: center;
      font-size: 0.3rem;
      vertical-align: middle;
    }
    .desc{
      border-radius: 0.1rem;
      display: inline-block;
      padding: 0.1rem;
      margin: 0.1rem 0;
      width: 6rem;
      height: auto;
      font-size: 0.3rem;
      vertical-align: middle;
      background:rgb(227, 221, 221);
      p{
        word-break: break-all;
      }
    }
    .datatype{
      display: inline-block;
      width: 1.5rem;
      height: auto;
      font-size: 0.3rem;
      text-align: center;
      vertical-align: middle;
    }
    
    

}
.popbutton{
    position: fixed;
    text-align: center;
    bottom: 0;
    .van-button{
        border-radius: 10px;
        width: 4.0rem;
        height: 1rem;
        margin: 0.5rem;

    }
    
}
</style>
